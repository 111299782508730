.confirm-info-wrap {
  width: 100%;
  overflow: hidden;
  .home-box {
    position: absolute;
    width: 90%;
    top: 7rem;
    bottom: 9rem;
    overflow: auto;
    padding: 5% 5%;
    background-color: #ecf3ec;
  }
  .receiver-info-box {
    p {
      margin: 0;
    }
    position: absolute;
    top: 5rem;
    bottom: 11rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    overflow: auto;
    .title {
      width: 100%;
      height: 1.3rem;
      font-size: 1.3rem;
      font-family: Poppins;
      font-weight: 600;
      color: #000000;
      margin-top: 2.7rem;
      margin-bottom: 2.7rem;
    }
  }
  .form-item-box {
    margin-bottom: 2rem;
  }
  .submit-btn {
    position: fixed;
    width: 34rem;
    height: 5.5rem;
    bottom: 0.8rem;
    left: 50%;
    margin-left: -17rem;
    z-index: 300;
    opacity: 0;
  }
}
