.trade-receiver-box{
    width:90%;
    background-color: #fff;
    padding: 5%;
    border-radius: 2px;
    margin-top: 1.6rem;
    margin-bottom: 3rem;
  }
  .trade-details-item{
    border-bottom: 1px solid #ECF3EC;
    &.border-bottom-none{
      border-bottom: none;
    }
    .trade-title{
      font-family: Montserrat-Bold;
      color: #000000;
      font-weight: bold;
      margin-top: 1.9rem !important;
      margin-bottom: 1.4rem !important;
      &.margin-top10{
        margin-top: 10px !important;
      }
    }
    .trade-item-box{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.8rem;
    }
    .trade-label{
      font-family: Regular;
      color: #0E2C0E;
      font-size: 1.3rem;
    }
    .trade-value{
      color: #0E2C0E;
      font-family: Montserrat-Bold;
      font-weight: bold;
      max-width: 20rem;
      word-break: break-all;
      font-size: 1.3rem;
    }
  }