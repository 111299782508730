.result-wrap {
  width: 100%;
  overflow: hidden;
  .result-box {
    p {
      margin: 0;
    }
    position: absolute;
    top: 5rem;
    bottom: 0;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    text-align: center;
    .result-status {
      width: 100%;
      text-align: center;
      margin-top: 6rem;
      img {
        width: 8rem;
      }
    }
    .result-text, .result-error {
      font-size: 1.8rem;
      font-family: Montserrat;
      font-weight: bold;
      color: #010819;
      margin: 1rem 0;
    }
    .tip-box{
      margin-top: 6rem;
    }
    .tip1 {
      font-size: 1.5rem;
      font-family: Montserrat;
      font-weight: bold;
      color: #000104;
      margin-bottom: 1rem;
    }
    .tip2 {
      font-size: 1.5rem;
      font-family: Montserrat;
      font-weight: 400;
      color: #707070;
      line-height: 2rem;
      margin-bottom: 5rem;
    }
    a{
      text-decoration: none;
    }
    .MuiButton-containedPrimary {
      width: 27rem;
      height: 5rem;
      background: #01a27e;
      border-radius: 3px;
      font-size: 1.4rem !important;
      font-family: Montserrat;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 1.3rem;
      &._blue {
        background: #4182e8;
      }
    }
    .MuiButton-text{
      width: 27rem;
      height: 5rem;
      font-size: 1.6em;
      font-family: Montserrat;
      font-weight: 400;
      color: #01A27E;
    }
    .MuiButton-label{
      text-transform: capitalize;
    }
  }
}
