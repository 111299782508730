.method-wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
  .method-box {
    position: absolute;
    top: 5.5rem;
    bottom: 9rem;
    font-size: 1.3rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    .method-group {
      width: 100%;
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiFormControlLabel-label {
        color: #000000;
        font-family: Montserrat-Bold;
        font-weight: bold;
        font-size: 1.3rem;
      }
      .method-item {
        width: 90%;
        background-color: #fff;
        margin-top: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5%;
        border-radius: 3px;
        .banktranfer-img {
          width: 2.5rem;
        }
        .pix-img {
          width: 4rem;
          margin-right: -0.5rem;
        }
        .paypal-img{
          width: 10rem;
        }
      }
    }
  }
  .submit-btn {
    position: fixed;
    width: 34rem;
    height: 5.5rem;
    bottom: 0.8rem;
    left: 50%;
    margin-left: -17rem;
    z-index: 300;
    opacity: 0;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #01a27e !important;
}
.MuiFormControlLabel-root {
  width: 100% !important;
}
.home__notice {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.34);
  .home__notice__warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    height: 40%;
    width: 90%;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #dee8f2;

    .home__notice__warningClose {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .home__notice__warningText {
      font-family: Montserrat-Bold;
      color: #01a27e;
      font-size: 16px;
      margin: 25px 0;
      text-align: center;
    }
    .home__notice__warningButton {
      width: 120px;
      height: 44px;
      background: rgba(20, 117, 223, 0);
      border: 1px solid #01a27e;
      border-radius: 20px;
      font-family: Montserrat-Regular;
      font-size: 16px;
      color: #01a27e;
      cursor: pointer;
    }
  }
}
