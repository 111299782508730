.country-wrap {
  width: 100%;
  height: auto;
  overflow: hidden;
  .country-box {
    position: absolute;
    top: 6.5rem;
    bottom: 9rem;
    font-size: 1.3rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
  }
  .submit-btn {
    position: fixed;
    width: 34rem;
    height: 5.5rem;
    bottom: 0.8rem;
    z-index: 300;
    opacity: 0;
  }
}
