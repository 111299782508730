.home-wrap {
  width: 100%;
  height: auto;
  overflow: hidden;

  .home-box {
    position: absolute;
    top: 7rem;
    bottom: 9rem;
    overflow: auto;
    p {
      margin: 0;
    }
    font-size: 1.3rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    .fill-information-box {
      width: 90%;
      height: 4rem;
      background-color: #e4e3e3;
      margin-top: 1.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5%;
      border-radius: 3px;
      &.finished {
        background-color: #fff;
      }
      .step-num {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        background-color: #949494;
        font-weight: bold;
        font-family: Montserrat-Bold;
        font-size: 1.4rem;
        color: #fff;
        margin-right: 1rem;
        &.finished {
          background-color: #01a27e;
        }
      }
      .step-text {
        font-size: 1.6rem;
        font-family: Montserrat-Bold;
        font-weight: bold;
        color: #000;
      }
      .step-in-img {
        width: 1rem;
      }
      .step-finnished {
        width: 1.7rem;
        &.show {
          display: block;
        }
      }
    }
  }
}
.form-btn-box {
  position: fixed;
  width: 100%;
  height: 9rem;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: Montserrat-Regular;
  color: #000000;
  font-size: 1.4rem;
  z-index: 200;
  border-top: 1 px solid #eee;
  .MuiButton-label{
    text-transform: capitalize;
    letter-spacing: 1px;
  }
}
.MuiButton-containedPrimary {
  position: relative;
  width: 90%;
  height: 5rem;
  background: #01a27e !important;
  border-radius: 3px;
  font-size: 1.6rem !important;
  font-family: DMSans-Bold;
  font-weight: bold;
  color: #ffffff;
  &:hover {
    background: #01a27e !important;
  }
  &.disabled {
    background-color: #ccc !important;
  }
}
