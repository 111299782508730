.footer-wrap {
  position: fixed;
  bottom: 6rem;
  left: 0;
  width: 100%;
  min-height: 5rem;
  flex-direction: column;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 201;
  padding: 0 0 3%;
  font-family: Montserrat-Regular;
  color: #000000;
  font-size: 14px;
  border-top: 1px solid #eee;
  &.show {
    z-index: 999;
  }
  .MuiButton-containedPrimary {
    position: relative;
    width: 32rem;
    height: 5rem;
    background: #01a27e !important;
    border-radius: 3px;
    font-size: 1.6rem;
    font-family: DMSans-Bold;
    font-weight: bold;
    color: #ffffff;
    &:hover {
      background: #01a27e !important;
    }
    &.disabled {
      background-color: #ccc !important;
    }
  }
  .footer-content {
    width: 80%;
  }
  .box1 {
    width: 100%;
    .item-name,
    .item-value {
      font-weight: normal !important;
    }
  }
  .box2 {
    width: 80%;
  }
  .con-item-box {
    display: flex;
    justify-content: space-between;
    margin: 0.9rem 0;
    &.box2{
      margin-bottom: -0.5rem;
    }
    .item-name {
      font-weight: bold;
      font-size: 1.4rem;
    }
    .item-value {
      font-weight: bold;
      font-size: 1.4rem;
      .currency {
        font-family: Montserrat-Bold;
        color: #01a27e;
        margin-right: 8px;
      }
      .tip {
        font-family: Montserrat-Regular;
        color: #000;
        font-size: 1.4rem;
      }
    }
  }
}
.confirm-box {
  position: fixed;
  width: 100%;
  bottom: 0.8rem;
  left: 50%;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat-Regular;
  color: #000000;
  font-size: 14px;
  margin-left: -45%;
  z-index: 100;
}
.toggle-btn {
  margin: 0 auto;
  width: 24px;
  padding: 0 100px;
}
.MuiButton-label {
  text-transform: capitalize;
}
.MuiButton-containedPrimary {
  width: 32rem;
  height: 5rem;
  background: #01a27e !important;
  border-radius: 3px;
  font-size: 1.6rem;
  font-family: DMSans-Bold;
  font-weight: bold;
  color: #ffffff;
  &:hover {
    background: #01a27e !important;
  }
  &.disabled {
    background-color: #ccc !important;
  }
}

