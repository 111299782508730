.trade-details-box{
    width:90% !important;
    background-color: #fff;
    padding: 5%;
    border-radius: 2px;
    margin-top: 0 !important;
  }
  .trade-details-item{
    border-bottom: 1px solid #ECF3EC;
    &.border-bottom-none{
      border: none;
    }
    .trade-title{
      font-family: Montserrat-Bold;
      color: #000000;
      font-weight: bold;
      margin-top: 1.9rem !important;
      margin-bottom: 1.4rem !important;
      &.margin-top10{
        margin-top: 10px !important;
      }
    }
    .trade-item-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.8rem;
    }
    .trade-tip{
      margin-left: 1rem;
    }
    .trade-label{
      font-family: Regular;
      color: #0E2C0E;
      font-size: 1.3rem;
    }
    .trade-value{
      color: #0E2C0E;
      font-family: Montserrat-Bold;
      font-weight: bold;
      font-size: 1.3rem;
      .payer-img{
        width: 4rem;
        height: 4rem;
        border: 1px solid #ccc;
      }
    }
  }