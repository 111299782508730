.headerMobile {
  position: fixed;
  z-index: 1000;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 21px 15px;
  background-color: #01a27e;
  .headerMobile__top {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    img{
      width: 100px;
    }
    .headerMobile__countryFlag {
      width: 34px;
      height: 22px;
      object-fit: contain;
      position: absolute;
      left: 0;
      bottom: 8px;
    }
  }
  .headerMobile__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back-arrow{
      width: 13px;
    }
    .headerMobile__info {
      cursor: pointer;
    }
  }
}
.header--langContainer {
  .header__langTextSelect {
    color: #ffffff;
    margin-right: 5px;
    font-size: 1.6rem;
  }
}
.header--langContainer:hover .header__langDropDown {
  left: 0;
  right: auto;
}
