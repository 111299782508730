.form-box {
  width: 100% !important;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiInputLabel-outlined {
  transform: translate(1.4rem, 1.5rem) scale(1) !important;
  border-color: #01a27e !important;
}
.MuiFormLabel-root .Mui-error {
  color: #f44336 !important;
}

.MuiTextField-root {
  background-color: #fff !important;
}

.form-select .MuiOutlinedInput-input {
  font-size: 1.5rem !important;
  padding: 0.5rem !important;
}

.MuiOutlinedInput-input {
  font-size: 1.5rem !important;
  padding: 1.4rem !important;
  background-color: #fff !important;
  border: 1px solid #ecebeb;
}
.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.MuiInputLabel-outlined {
  font-size: 1.5rem !important;
  font-family: Montserrat;
  font-weight: 400;
  color: #707070;
}
.MuiInputLabel-shrink {
  transform: translate(1.6rem, -0.6rem) scale(0.75) !important;
}

.MuiAutocomplete-endAdornment {
  .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
  }
}
.MuiAutocomplete-clearIndicator{
  .MuiSvgIcon-root {
    width: 15px !important;
    height: 15px !important;
  }
}

.form-error {
  color: #f44336;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.03333em;
  margin-top: 0.5rem;
  margin-bottom: -1rem !important;
}
.MuiFormControl-root span{
  min-width: 30px;
  font-size: 12px;
}
.PrivateNotchedOutline-legendNotched-4{
  min-width: 30px !important;
}