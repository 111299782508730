.receiver-info-wrap {
  width: 100%;
  overflow: hidden;
  .receiver-info-box {
    p {
      margin: 0;
    }
    position: absolute;
    top: 5rem;
    bottom: 27rem;
    width: 90%;
    padding: 5%;
    background-color: #ecf3ec;
    overflow: auto;
    z-index: 200;
    .title {
      width: 100%;
      height: 1.3rem;
      font-size: 1.3rem;
      font-family: Poppins;
      font-weight: 600;
      color: #000000;
      margin-top: 2.7rem;
      margin-bottom: 2.7rem;
    }
  }
  .form-item-box {
    margin-bottom: 2rem;
  }
  .submit-btn {
    position: fixed;
    width: 34rem;
    height: 5.5rem;
    bottom: 0.8rem;
    left: 50%;
    margin-left: -17rem;
    z-index: 200;
    opacity: 0;
    -webkit-tap-highlight-color: transparent;
  }
}
.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: Montserrat-Regular;
  font-size: 1.3rem;
  margin-bottom: 1rem !important;
  .privacy-box {
    margin-left: 6px;
    color: #6a6f7a;
    font-family: Montserrat-Regular;
    font-size: 1.3rem;
  }
  .privacy {
    color: #0376ff;
    cursor: pointer;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #01a27e !important;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 2rem;
  }
}
.privacy-policy-error {
  font-size: 1.2rem;
  font-family: Montserrat-Regular;
  color: #f44336;
  margin-left: 1.3rem !important;
  margin-bottom: 1rem !important;
}
