.privacyPolicy {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(188, 189, 189, 0.34);
  .privacyPolicy__content {
    font-family: Montserrat-Regular;
    padding: 4rem;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 5%;
    right: 5%;
    overflow: scroll;
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.6rem;
    }
    p {
      margin: 1rem 0;
      font-size: 1.2rem;
    }
    ul {
      font-size: 1.2rem;
      li{
        margin: 1rem 0;
      }
    }
    .privacyPolicy__content__back {
      position: fixed;
      top: 11%;
      right: 8%;
      cursor: pointer;
      padding: 1rem;
    }
  }
}
